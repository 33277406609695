.contact-header{
    /* background-image: url('../../../assets/contactus-01.jpg'); */
    height:22rem!important;
    max-height: 22rem !important;
    overflow: hidden;
    /* background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover; */
    width:100%;
    color:#fff;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .box-gallery .box-panel{
    height:23rem !important;
} */
.frameDiv{
    border:1px solid #888;
    width:450px;
    height:450px;
}
.contactus-tel-adress{
    
}
.cover {
    object-fit: cover;
        
    height: 26rem;
  }
@media only screen and (max-width: 756px) {
    .contact-header{
        height:14rem;
    }
    .frameDiv{
        border:1px solid #888;
        width:250px;
        height:250px;
    }
}
@media only screen and (max-width: 1100px) {
    .contact-header{
        height:14rem;
    }
    .frameDiv{
        border:1px solid #888;
        width:270px;
        height:270px;
    }
}