/* @font-face {
  font-family: 'DIN Next LT Arabic Regular' !important;
  src: local('DIN Next LT Arabic Regular'), url('./fonts/699a5dddcff7f1cbb08d964cc275cb50ce7020291901931.ttf') format('truetype');
} */
html {
  width: auto !important;
  overflow-x: hidden !important;
}
body {
  margin: 0;
  height: 100%;
  width: auto !important;
  overflow-x: hidden !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
a,
u {
  text-decoration: none;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
.mainpageDivBg {
  background-image: url("./assets/mainpage-hämtning.jpg");
  height: 30rem;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  width: 100%;
  color: #000;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.mainPage-bg {
  background-image: url("./assets/mainpage-header.jpg");
  height: 45rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.loading {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 756px) {
  .mainpageDivBg {
    height: 30rem;
  }
  .mainPage-bg {
    height: 30rem;
  }
}
