.lagningBg{
  width:100%;
  height:auto;
  }
  video{
    background-color: #FCFBFB;
    
  }
  .filtering{
    position: absolute;
    width:50px;
    height:50px;
    background-color: black;
    top:90px;
    left:10px;
  }
  @media only screen and (max-width: 756px) {
    .filtering{
      
      top:50px;
      left:10px;
    }
  }
#player{
  position: relative;
}