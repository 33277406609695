.bm-burger-button{
    display: none!important;
}
.bm-burger-bars {
    background: #373a47;
    height:1rem
  }
  .burgerHidden{
      display:none!important;
  }
@media (max-width:768px){
    .bm-menu-wrap{
        width:200px!important;
    }
    .bm-burger-button{
        display: block!important;
        left:15px!important;
        position: relative!important;
        width:1.75rem!important;
        height:1.55rem!important;
        top: 2px!important;
    }
    .bm-burger-button button{
        height:0.5rem;
        top:0!important;
        left:0!important
    }
    
}