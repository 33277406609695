.image-gallery-slide img{
  width:93%!important;
margin-top:2rem
}
.styles_gridItem__nPXhF img {
opacity: 1!important;
}
@media only screen and (max-width: 756px) {
  .image-gallery-slides{
    height:28rem
  }
  .styles_gridItem__nPXhF{
    max-width: 90%!important;
  }
}